import React from 'react'
import logos from "./nbl.png";
import { Grid } from '@mui/material';
import './Navbar.css'
import dex from './dexc.png'
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
function Navbar() {
  return (
    <div className=''>
   <Grid container spacing={0}>
    <Grid item md={3} lg={3}>
<h1 className='bizlogo'>Bizcat</h1>

    </Grid>
    <Grid item md={6} lg={6} style={{position:"relative" , top:"20px"}}>


    <div className='centeritall' >
<a href='https://twitter.com/BizCatX'>
   <FaXTwitter className='socials'/>
</a>
&nbsp;&nbsp;&nbsp;
<a href='https://t.me/BizCatSol'>
<FaTelegramPlane className='socials'/>
</a>
&nbsp;&nbsp;&nbsp;
<a href='https://dexscreener.com/solana/ol4qhmwx9zkxj4fmukjgmjcvuati6uwzym9hoeeihns'>
<img src={dex} style={{width:"50px"}}/>
</a>

         </div>


    </Grid>

    <Grid item md={3} lg={3} className='buttonBuy'>
      <a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=8tk1Q4uu7qo73wQ15QaHcR6WLnTeTnVbYVAjEQfq9hfW&fixed=in'>



      <button class="btn-53">
  <div class="original">BUY</div>
  <div class="letters">
    
    <span>B</span>
    <span>U</span>
    <span>Y</span>

  </div>
</button>





      </a>


    </Grid>
   </Grid>

    </div>
  )
}

export default Navbar