import React from 'react'
import { Grid } from '@mui/material'
import Marquee from "react-fast-marquee";
import dex from './dexc.png'
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import biz from './nbiz.png'
import Navbar from './NavBar/Navbar'

function Desktop() {
  return (
  <div className='backofdesk'>
   <Navbar/>
        <div className='centeritall'>
        <img src={biz} className='topimg'/>
         </div> 
         <h1 className='maintext'>This cat means BIZ-ness</h1> <br/>
      

<div className='centeritall' >



<a href='https://twitter.com/BizCatX'>



<button class="btn-53">
<div class="original">Twitter</div>
<div class="letters">

<span>T</span>
<span>W</span>
<span>I</span>
<span>T</span>
<span>T</span>
<span>E</span>
<span>R</span>
</div>
</button>





</a>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

<a href='https://t.me/BizCatSol'>



<button class="btn-53">
<div class="original">Telegram</div>
<div class="letters">

<span>T</span>
<span>e</span>
<span>l</span>
<span>e</span>
<span>g</span>
<span>r</span>
<span>a</span>
<span>m</span>
</div>
</button>





</a>

</div>
         <br/>
  </div>
  )
}

export default Desktop