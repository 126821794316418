import React from 'react'
import { Grid } from '@mui/material'
import biz1 from './4.png'
import biz2 from './3.png'
import biz3 from './n3.png'
import biz4 from './n4.png'
function Footer() {
  return (
    <div className='coloroffooter'>
<div className='container'>
    <br/>
    <Grid container spacing={2}>
    <Grid item md={6} lg={6} sm ={12} xs={12} className='centeritall'>
<img src={biz1} style={{width:"100%" , borderRadius:"10px"}}/>

    </Grid>
    <Grid item md={6} lg={6} sm ={12} xs={12}>
<img src={biz2} style={{width:"100%", borderRadius:"10px"}}/>

    </Grid>
    <Grid item md={6} lg={6} sm ={12} xs={12}>
<img src={biz3} style={{width:"100%", borderRadius:"10px"}}/>

    </Grid>
    <Grid item md={6} lg={6} sm ={12} xs={12}>
<img src={biz4} style={{width:"100%", borderRadius:"10px"}}/>

    </Grid>
   </Grid>
</div>
<br/><br/>

<div className='centeritall' >



<a href='https://twitter.com/BizCatX'>



<button class="btn-53">
<div class="original">Twitter</div>
<div class="letters">

<span>T</span>
<span>W</span>
<span>I</span>
<span>T</span>
<span>T</span>
<span>E</span>
<span>R</span>
</div>
</button>





</a>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

<a href='https://t.me/BizCatSol'>



<button class="btn-53">
<div class="original">Telegram</div>
<div class="letters">

<span>T</span>
<span>e</span>
<span>l</span>
<span>e</span>
<span>g</span>
<span>r</span>
<span>a</span>
<span>m</span>
</div>
</button>





</a>

</div>

<p className='centerDCa'> © 2024 by BIZCAT. All rights reserved!</p>

<br/>
    </div>
  )
}

export default Footer