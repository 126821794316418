import React from 'react'
import { Grid } from '@mui/material'
import one from './lof.png'
import ones from './lof.png'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
function Desktopthree() {
  return (
    <div className='container'>
<br/>
<h1 className='maintextos'>Contract Address</h1> 
<br/>
<Grid  container spacing={2}>
<Grid  item md={12} lg={12} xs={12} sm={12}>
<h2 className='maintextost'>8tk1Q4uu7qo73wQ15QaHcR6WLnTeTnVbYVAjEQfq9hfW</h2>
</Grid>
</Grid>

<br/>
<div className='centeritall'>

<img src={one}  style={{width:"100%"}}/>

</div>
<br/>
    </div>
  )
}

export default Desktopthree